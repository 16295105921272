import React from 'react';
import styled from 'styled-components';

function PastDownloads({ downloadDates }) {
  const tableData = downloadDates.map(dd => {
    const from = dd[1];
    const to = dd[2];
    const user = dd[3];
    return (
      <tr key={dd[0]}>
        <Td>{from}</Td>
        <Td>{to}</Td>
        <Td>{user}</Td>
      </tr>
    );
  });
  const dateTable = (
    <Table border="1">
      <tbody>
        <tr>
          <TdHeader>Start date</TdHeader>
          <TdHeader>End date</TdHeader>
          <TdHeader>Triggered By</TdHeader>
        </tr>
        {tableData}
      </tbody>
    </Table>
  );

  return (
    <>
      <h2>Last 10 downloads</h2>
      {dateTable}
    </>
  );
}

const Table = styled.table`
  border: 1px solid;
  background-color: white;
`;
const Td = styled.td`
  border: 1px solid;
  padding: 2px;
`;
const TdHeader = styled(Td)`
  text-align: center;
`;

export default PastDownloads;
