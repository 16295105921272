import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import XLSX from 'xlsx';
import moment from 'moment';
import Datetime from 'react-datetime';

import DateTimeStyle from './date-time-style.css';

function Report({
  email,
  password,
  reportStartDate,
  setLoading,
  setLoadingMessage,
  setError,
  setErrorBody
}) {
  const [startDate, setStartDate] = useState(reportStartDate);
  const [endDate, setEndDate] = useState();

  async function getSpreadSheet(start, end) {
    const payload = {
      email,
      password,
      startDateString: start,
      endDateString: end
    };
    const response = await fetch('/downloadReport', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(payload)
    });
    const spreadSheet = await response.json();
    return spreadSheet;
  }

  async function downloadReport2() {
    // console.log('reportdownload: ', startDate, endDate);
    try {
      setLoading(true);
      setLoadingMessage('Initializing Authorize.net Connection...');
      const start = moment(startDate);
      const end = moment(endDate);

      if (!start) {
        throw new Error('Choose start date');
      }

      setLoadingMessage('Fetching records from Authorize.net...');
      const spreadSheet = await getSpreadSheet(
        moment(start).utcOffset(0),
        moment(end).utcOffset(0)
      );
      if (spreadSheet.error) {
        setError(true);
        setErrorBody(spreadSheet.body);
        setLoading(false);
        return;
      }

      setLoadingMessage('Saving Fetched records as Spreadsheet...');
      const from = moment(start)
        .utcOffset(-7)
        .format('MM-DD-YYYYThh-mm-AZZ');
      const to = moment(end)
        .utcOffset(-7)
        .format('MM-DD-YYYYThh-mm-AZZ');

      XLSX.writeFile(spreadSheet, `autorizeNetReport_${from}_${to}.csv`);
      window.location.reload();
    } catch (err) {
      setError(true);
      setErrorBody(err.body || err);
      setLoading(false);
    }
  }

  const downloadReport = useCallback(() => downloadReport2(), [startDate, endDate]);

  return (
    <>
      <Title>Authorize.net to CSV downloader</Title>
      <Label htmlFor="startDate">Start date:</Label>
      <Datetime
        style={DateTimeStyle}
        defaultValue={reportStartDate}
        onChange={v => setStartDate(v)}
      />
      <Label htmlFor="endDate">End date:</Label>
      <Datetime style={DateTimeStyle} onChange={v => setEndDate(v)} />
      <DownloadButton type="button" onClick={downloadReport}>
        Download Report
      </DownloadButton>
    </>
  );
}

export default Report;

const Title = styled.h1`
  text-align: center;
  margin: 15px;
`;

const DownloadButton = styled.button`
  width: 250px;
  height: 70px;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  padding: 15px;
  margin: 15px;
  border: solid darkgreen;
  border-radius: 10px;
  background-color: green;
`;

const Label = styled.label`
  font-weight: bold;
`;
