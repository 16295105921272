import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';

import './App.css';

import ErrorPopup from './components/error';
import Login from './components/login';
import Registration from './components/registration';
import Report from './components/report';
import PastDownloads from './components/past-downloads';

import logo from './images/logo.svg';
import slogan from './images/slogan.svg';
import backgroundWaves from './images/waves.svg';

function App() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [error, setError] = useState();
  const [errorBody, setErrorBody] = useState({});

  const [loading, setLoading] = useState();
  const [loadingMessage, setLoadingMessage] = useState('Loading...');

  const [view, setView] = useState(1);

  const [downloadDates, setDownloadDates] = useState([]);
  const [reportStartDate, setReportStartDate] = useState();

  async function attempLogin2() {
    try {
      setLoading(true);
      const payload = JSON.stringify({ email, password });
      const responce = await fetch('/login', {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: payload
      });
      const json = await responce.json();
      if (json.error) {
        setError(true);
        setErrorBody(json.body);
        setLoading(false);
        return;
      }
      if (json.length) {
        setDownloadDates(json);
        const startDateString = json[0][2];
        const startDate = moment(startDateString, 'MM-DD-YYYYThh-mm-AZZ');
        setReportStartDate(startDate);
      }
      setLoading(false);
      setError(false);
      setErrorBody(false);
      setView(3);
    } catch (err) {
      if (err.includes("Cannot read property 'length' of undefined")) {
        // TODO: set error in TrackVia
        setErrorBody('No Transactions Found. Please Try Again!');
      } else {
        setErrorBody(err);
      }
      setError(true);
      setLoading(false);
    }
  }

  const attempLogin = useCallback(() => {
    attempLogin2();
  }, [email, password]);

  const showRegistrationForm = useCallback(() => setView(2), []);

  // Login
  if (view === 1) {
    return (
      <Conteiner>
        {error ? (
          <ErrorPopup errorBody={errorBody} setError={setError} setErrorBody={setErrorBody} />
        ) : (
          ''
        )}
        {loading ? <Loading>Loading...</Loading> : ''}
        <Logo src={logo} />
        <Login setEmail={setEmail} setPassword={setPassword} attempLogin={attempLogin} />
        <p>Or</p>
        <RegistrationButton type="button" onClick={showRegistrationForm}>
          Register
        </RegistrationButton>
        <Slogan src={slogan} />
      </Conteiner>
    );
  }

  // Registration
  if (view === 2) {
    return (
      <Conteiner>
        {error ? (
          <ErrorPopup errorBody={errorBody} setError={setError} setErrorBody={setErrorBody} />
        ) : (
          ''
        )}
        {loading ? <Loading>Loading...</Loading> : ''}
        <Registration setView={setView} />
      </Conteiner>
    );
  }

  // Report download
  if (view === 3) {
    return (
      <Conteiner>
        {error ? (
          <ErrorPopup errorBody={errorBody} setError={setError} setErrorBody={setErrorBody} />
        ) : (
          ''
        )}
        {loading ? <Loading>{loadingMessage}</Loading> : ''}
        <Report
          email={email}
          password={password}
          reportStartDate={reportStartDate}
          setLoading={setLoading}
          setLoadingMessage={setLoadingMessage}
          setError={setError}
          setErrorBody={setErrorBody}
        />
        <PastDownloads downloadDates={downloadDates} />
      </Conteiner>
    );
  }

  return <h1>Something went wrong, restart and try again</h1>;
}

export default App;

// Loading
const Loading = styled.div`
  position: absolute;
  display: flex;
  font-size: 24px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgb(128, 128, 128, 0.8);
`;

const Conteiner = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #eef7f7;
  background-image: url(${backgroundWaves});
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
`;

const Logo = styled.img`
  height: 6rem;
  margin-bottom: 15px;
`;
const Slogan = styled.img`
  height: 2rem;
`;

const RegistrationButton = styled.button`
  box-sizing: border-box;
  font-weight: bold;
  min-height: min-content;
  width: 400px;
  color: white;
  border: solid 1px grey;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 15px;
  background-color: rgba(0, 128, 255, 1);
  :hover {
    background-color: rgba(0, 128, 255, 0.75);
  }
`;
