import React, { useState, useCallback } from 'react';
import styled from 'styled-components';

function Registration({ setView }) {
  const [firstName, setFirstName] = useState();
  const [firstNameError, setFirstNameError] = useState();

  const [lastName, setLastName] = useState();
  const [lastNameError, setLastNameError] = useState();

  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();

  const [password, setPassword] = useState();
  const [passwordError, setPasswordError] = useState();

  const [password2, setPassword2] = useState();
  const [password2Error, setPassword2Error] = useState();

  const [error, setError] = useState();
  const [errorBody, setErrorBody] = useState({});

  const [loading, setLoading] = useState();

  function verifyForm() {
    let verified = true;
    if (!firstName) {
      verified = false;
      setFirstNameError('First name is required');
    }

    if (!lastName) {
      verified = false;
      setLastNameError('Last name is required');
    }

    if (!email) {
      verified = false;
      setEmailError('Email is required');
    }

    if (!password || password.length < 6) {
      verified = false;
      setPasswordError('Password should be at least 6 characters long');
    }
    if (password !== password2) {
      verified = false;
      setPassword2Error('Passwords do not match');
    }
    return verified;
  }

  async function attempRegistration2() {
    try {
      const verified = verifyForm();
      if (!verified) return;

      setLoading(true);
      const payload = JSON.stringify({
        firstName,
        lastName,
        email,
        password
      });
      const responce = await fetch('/register', {
        headers: {
          'Content-Type': 'application/json'
        },
        method: 'POST',
        body: payload
      });
      const json = await responce.json();
      if (json.error) {
        setError(true);
        setErrorBody(json.body);
      } else {
        setView(1);
      }

      setLoading(false);
    } catch (err) {
      setError(true);
      setErrorBody(err);
      setLoading(false);
    }
  }

  const attempRegistration = useCallback(() => attempRegistration2(), [
    firstName,
    lastName,
    email,
    password,
    password2
  ]);

  const goBackToMainScreen = useCallback(() => setView(1), []);

  const errorPopup = (
    <ConteinerErrorPopup
      onClick={() => {
        setError(false);
      }}
    >
      <ErrorPopup>
        <p>{errorBody.message}</p>
        <p>
          {errorBody.status ? `Status: ${errorBody.status}` : ''}{' '}
          {errorBody.code ? `Code: ${errorBody.code}` : ''}
        </p>
      </ErrorPopup>
    </ConteinerErrorPopup>
  );

  return (
    <>
      {error ? errorPopup : ''}
      {loading ? <Loading>Loading...</Loading> : ''}
      <Form>
        <Fieldset>
          <Legend>Registration form</Legend>
          <Label htmlFor="firstName">First name*</Label>
          <Input
            id="firstName"
            type="text"
            required
            onChange={e => {
              setFirstName(e.target.value);
              if (firstNameError) setFirstNameError(false);
            }}
          />
          <ErrorContainer>{firstNameError}</ErrorContainer>
          <Label htmlFor="lastName">Last name*</Label>
          <Input
            id="lastName"
            type="text"
            required
            onChange={e => {
              setLastName(e.target.value);
              if (lastNameError) setLastNameError(false);
            }}
          />
          <ErrorContainer>{lastNameError}</ErrorContainer>
          <Label htmlFor="email">Email*</Label>
          <Input
            id="email"
            type="email"
            required
            onChange={e => {
              setEmail(e.target.value);
              if (emailError) setEmailError(false);
            }}
          />
          <ErrorContainer>{emailError}</ErrorContainer>
          <Label htmlFor="password">Password*</Label>
          <Input
            id="password"
            type="password"
            required
            onChange={e => {
              setPassword(e.target.value);
              if (passwordError) setPasswordError(false);
            }}
          />
          <ErrorContainer>{passwordError}</ErrorContainer>
          <Label htmlFor="password2">Confirm password*</Label>
          <Input
            id="password2"
            type="password"
            required
            onChange={e => {
              setPassword2(e.target.value);
              if (password2Error) setPassword2Error(false);
            }}
          />
          <ErrorContainer>{password2Error}</ErrorContainer>
          <RegisterButton type="button" onClick={attempRegistration}>
            Register
          </RegisterButton>
        </Fieldset>
      </Form>
      <BackButton type="button" onClick={goBackToMainScreen}>
        Back
      </BackButton>
    </>
  );
}

// styled-components
// Loading
const Loading = styled.div`
  position: absolute;
  display: flex;
  font-size: 24px;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: rgb(128, 128, 128, 0.8);
`;

// Error pop-up
const ConteinerErrorPopup = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: rgba(128, 128, 128, 0.95);
`;

const ErrorPopup = styled.div`
  max-width: 600px;
  padding: 15px;
  margin: auto;
  border: solid red 10px;
  background-color: white;
`;

// Form
const Form = styled.form`
  margin: 10px;
`;
const Fieldset = styled.fieldset`
  width: 400px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  border-radius: 5px;
`;
const Legend = styled.legend`
  font-size: 1.5rem;
`;
const Label = styled.label`
  font-weight: bold;
`;
const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 5px;
`;

// Error
const ErrorContainer = styled.div`
  color: red;
  min-height: 21px;
`;

// Buttons
const Button = styled.button`
  box-sizing: border-box;
  font-weight: bold;
  min-height: min-content;
  color: white;
  border: solid 1px;
  border-radius: 5px;
  padding: 10px;
`;

const RegisterButton = styled(Button)`
  width: 100%;
  background-color: rgba(0, 128, 255, 1);
  :hover {
    background-color: rgba(0, 128, 255, 0.75);
  }
`;
const BackButton = styled(Button)`
  width: 100px;
  background-color: rgba(200, 0, 0, 1);
  :hover {
    background-color: rgba(200, 0, 0, 0.7);
  }
`;

export default Registration;
