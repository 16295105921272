import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

function Login({ setEmail, setPassword, attempLogin }) {
  return (
    <>
      <Form>
        <Fieldset>
          <Label htmlFor="email">Email</Label>
          <Input
            type="email"
            placeholder="Enter your email"
            onChange={e => setEmail(e.target.value)}
          />
          <Label htmlFor="password">Password</Label>
          <Input
            type="password"
            placeholder="Enter your password"
            onChange={e => setPassword(e.target.value)}
          />
          <LoginButton type="button" onClick={attempLogin}>
            Login
          </LoginButton>
        </Fieldset>
      </Form>
    </>
  );
}

// prop-types

Login.propTypes = {
  setEmail: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  attempLogin: PropTypes.func.isRequired
};

// styled-components

const Form = styled.form`
  margin: 10px;
`;
const Fieldset = styled.fieldset`
  min-width: 400px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  border: solid 1px grey;
  border-radius: 5px;
  background-color: #eef7f7;
`;
/* const Legend = styled.legend`
  font-size: 1.5rem;
`; */
const Label = styled.label`
  font-weight: bold;
  margin: 5px 0;
`;
const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  margin: 5px 0 10px 0;
  padding: 5px;
  border: solid 1px grey;
`;
const LoginButton = styled.button`
  box-sizing: border-box;
  font-weight: bold;
  min-height: min-content;
  width: 100%;
  color: white;
  border: solid 1px;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px;
  background-color: rgba(0, 172, 0, 1);
  :hover {
    background-color: rgba(0, 172, 0, 0.75);
  }
`;

export default Login;
